import React, { useMemo } from "react"
import { Link } from "gatsby"
import _find from "lodash/find"
import "./PredictionPower.css"

import { LoadingIndicator } from "../../../../components"
import { useScenarioState } from "../../../Scenario/state"
import { pluralise } from "../../../../lib/utils"

export const PredictionPower = () => {
  const {
    predictionListState: { isFetchingList: isFetchingPredictions, predictions },
    scenarioListState: { isFetchingList: isFetchingScenarios, scenarios },
  } = useScenarioState()

  const resolvedPredictions = useMemo(() => {
    return predictions.filter(p => {
      const scenario = _find(scenarios, ["id", p.scenario.id])
      if (scenario && scenario.resolution) {
        return true
      }
      return false
    })
  }, [predictions, scenarios])
  const correctPredictions = useMemo(() => {
    return predictions.filter(p => {
      const scenario = _find(scenarios, ["id", p.scenario.id])
      if (scenario && scenario.resolution) {
        if (scenario.resolution.optionId === p.scenarioOption.id) {
          return true
        }
      }
      return false
    })
  }, [predictions, scenarios])
  const accuracyPercentage = useMemo((): number => {
    return Math.round(
      (correctPredictions.length / resolvedPredictions.length) * 100
    )
  }, [correctPredictions, resolvedPredictions])

  const isFetching = isFetchingPredictions || isFetchingScenarios
  const numPredictions = predictions.length
  const numResolvedPredictions = resolvedPredictions.length
  const numCorrectPredictions = correctPredictions.length
  const numOpenPredictions = predictions.length - resolvedPredictions.length

  return (
    <div>
      {isFetching ? (
        <LoadingIndicator />
      ) : (
        <div className="PredictionStats">
          {numPredictions > 0 && (
            <>
              {numOpenPredictions > 0 && (
                <p>
                  You have {numOpenPredictions} open{" "}
                  {pluralise("prediction", "predictions", numOpenPredictions)}.
                </p>
              )}
              {numResolvedPredictions > 0 && (
                <p>
                  You have made {numCorrectPredictions}/{numResolvedPredictions}{" "}
                  correct predictions.
                </p>
              )}
              {numResolvedPredictions > 0 && (
                <p>You have an accuracy of {accuracyPercentage}%</p>
              )}
            </>
          )}
          {numPredictions === 0 && (
            <div>
              <p>You haven't made any predictions yet. </p>
              <Link to="/">Browse questions.</Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
