import React, { useEffect } from "react"
import { navigateTo } from "gatsby"

import { SEO, Layout, TextLink, LoadingIndicatorPage } from "../components"
import { useAuthState } from "../features/Auth/state"
import { PredictionPower } from "../features/Profile/components"

const ProfilePage = () => {
  const { initialising, isAnonymous, emailAddress, onLogout } = useAuthState()

  useEffect(() => {
    if (!initialising && isAnonymous) {
      navigateTo("/login")
    }
  }, [initialising, isAnonymous])

  return (
    <Layout isProfilePage>
      <SEO title="Tipsta Profile" />
      {initialising ? (
        <LoadingIndicatorPage />
      ) : (
        <section className="wrapper-small">
          <h2>Tipsta Profile</h2>
          <div>
            <PredictionPower />
          </div>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <p style={{ margin: 0 }}>
              Logged in as <strong>{emailAddress}</strong>.
            </p>
            <div>
              <TextLink onClick={onLogout}>Logout</TextLink>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default ProfilePage
